<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('eas.基本信息') }}</div>
      <van-collapse-item :title="$t('eas.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('eas.销售发票号') }}</h5>
          <p>{{ ruleForm.invoiceNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.开票日期') }}</h5>
          <p>{{ ruleForm.invoiceDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.审批状态') }}</h5>
          <p>{{ ruleForm.status | setDict('PUB_WF_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.真实发票号') }}</h5>
          <p>{{ ruleForm.writeBackInvoiceNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.销售客户') }}</h5>
          <p>{{ ruleForm.salesCustomer }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.客户分类') }}</h5>
          <p>{{ ruleForm.customerType }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.税票类别') }}</h5>
          <p>{{ ruleForm.taxReceiptCategory | setDict('TAX_RECEIPT_CATEGORY') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.税率') }}</h5>
          <p>{{ ruleForm.vatRate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.发票币种') }}</h5>
          <p>{{ ruleForm.currency }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.发票金额') }}</h5>
          <p>{{ ruleForm.currencyAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.业务员') }}</h5>
          <p>{{ ruleForm.saleBizUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.税额') }}</h5>
          <p>{{ ruleForm.totalVatAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.备注') }}</h5>
          <p>{{ ruleForm.remark }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('eas.发货明细')" name="5">
        <div v-if="!ruleForm.deliveryCommodityList || !ruleForm.deliveryCommodityList.length">{{ $t('eas.无') }}</div>
        <div v-for="(item, index) in ruleForm.deliveryCommodityList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('eas.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.销售合同号') }}:</h5>
            <p>{{ item.salesContractNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.发货单号') }}:</h5>
            <p>{{ item.deliveryNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.退货单号') }}:</h5>
            <p>{{ item.returnApplyNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.商品编码') }}:</h5>
            <p>{{ item.commodityCode }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.商品名称') }}:</h5>
            <p>{{ item.commodityName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.工厂型号') }}:</h5>
            <p>{{ item.specification }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.单位') }}:</h5>
            <p>{{ item.unitName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.数量') }}:</h5>
            <p>{{ (item.orderType.toLowerCase() === 'return' ? -item.quantity : item.quantity) | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.税率') }}:</h5>
            <p>{{ item.vatRate }}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.含税单价') }}:</h5>
            <p>{{ (item.orderType.toLowerCase() === 'return' ? -item.priceIncludeTax : item.priceIncludeTax) | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.不含税单价') }}:</h5>
            <p>{{ (item.orderType.toLowerCase() === 'return' ? -item.priceExcludeTax : item.priceExcludeTax) | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.原币含税金额') }}:</h5>
            <p>{{ (item.orderType.toLowerCase() === 'return' ? -item.amountIncludeTax : item.amountIncludeTax) | formatAmount }}</p>
          </div>
          <div class="items" v-if="ruleForm.saleContractCategory === '2' && ruleForm.orgId === '517730389255520257'">
            <h5>FOB原币金额:</h5>
            <p>{{ (item.orderType.toLowerCase() === 'return' ? -item.fobAmount : item.fobAmount) | formatAmount }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('eas.开票明细') }}</div>
      <van-collapse-item :title="$t('eas.开票明细')" name="6">
        <div v-if="!ruleForm.commodityDetailList || !ruleForm.commodityDetailList.length">{{ $t('eas.无') }}</div>
        <div v-for="(item, index) in ruleForm.commodityDetailList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('eas.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.开票名称') }}:</h5>
            <p>{{ item.commodityName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.工厂型号') }}:</h5>
            <p>{{ item.specification }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.单位') }}:</h5>
            <p>{{ item.unitName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.数量') }}:</h5>
            <p>{{ item.quantity | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.不含税单价') }}:</h5>
            <p>{{ item.priceExcludeTax | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.含税单价') }}:</h5>
            <p>{{ item.priceIncludeTax | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.税率') }}:</h5>
            <p>{{ item.vatRate ? item.vatRate+'%':'' }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.税额') }}:</h5>
            <p>{{ item.vatAmount | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.含税金额') }}:</h5>
            <p>{{ item.amountIncludeTax | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.折扣金额') }}:</h5>
            <p>{{ item.discountAmount | formatAmount }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('eas.附件') }}</div>
      <van-collapse-item :title="$t('eas.附件信息')" name="3">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('eas.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('eas.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('eas.系统信息') }}</div>
      <van-collapse-item :title="$t('eas.系统信息')" name="8">
        <div class="items">
          <h5>{{ $t('eas.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
  import { Dialog } from 'vant';
  import util from '../../../../libs/util';
  import myHistoryPanel from '@/views/business/components/myHistoryPanel';
  import myApproval from '@/views/business/components/myApproval';

  export default {
    name: 'visitorFieldComponent',
    components: {
      myHistoryPanel,
      myApproval
    },
    data() {
      return {
        loading: false,
        finished: false,
        activeNames: '1',
        hostName: '',
        task: '',
        personId: '',
        approvalShow: false,
        customSubmitBtnName: ['同意', '不同意'],
        id: '',
        key: '',
        title: '',
        detail: null,
        active: 0,
        businessKey: '',
        mappingId: '',
        taskInstanceId: '',
        currentNode: '',
        variables: {},
        taskType: '',
        ruleForm: {
          id: '',
          orgId: '',
          deptId: '',
          orderStatus: '',
          processInstanceId: '',
          /**
           * 发票号
           */
          invoiceNo: '',
          /**
           * 业务员
           */
          salesMan: '',
          /**
           * 状态
           */
          status: 'PREPARING',
          /**
           * 开票日期
           */
          invoiceDate: '',
          /**
           * 销售客户Id
           */
          salesCustomerId: '',
          /**
           * 销售客户
           */
          salesCustomer: '',
          salesCustomerCode: '',
          /**
           * 原币金额
           */
          currencyAmount: '',
          /**
           * 开票性质
           */
          invoiceNature: '',
          /**
           * 交易地区
           */
          tradingArea: '',
          /**
           * 回写真实发票号
           */
          writeBackInvoiceNo: '',
          /**
           * 税票类别
           */
          taxReceiptCategory: '2',
          /**
           * 币种
           */
          currency: 'RMB',
          /**
           * 开票客户Id
           */
          invoiceCustomerCode: '',
          /**
           * 开票客户
           */
          invoiceCustomer: '',
          /**
           * 纳税人识别号
           */
          taxpayerIdentificationNumber: '',
          /**
           * 地址
           */
          address: '',
          /**
           * 电话
           */
          phone: '',
          /**
           * 开户行
           */
          bank: '',
          /**
           * 账号
           */
          account: '',
          /**
           * 回写清单标志
           */
          writeBackListFlag: '',
          /**
           * 回写作废标志
           */
          writeBackVoidFlag: '',
          /**
           * 回写客户名称
           */
          writeBackCustomerName: '',
          /**
           * 回写发票类别代码
           */
          writeBackInvoiceCategoryCode: '',
          /**
           * 备注
           */
          remark: '',
          /**
           * 扩大共享
           */
          expandSharing: '',
          /**
           * 冲销预付款
           */
          writeOffAdvancePayment: '',
          /**
           * 创建人 Name
           */
          createByName: '',
          createByCode: '',

          /**
           * 销售合同号
           */
          contractNo: '',

          /**
           * 开票明细和发货明细是否一致
           */
          hasSameMaterial: 'N',
          /**
           * 是否生效
           */
          effective: 'N',
          // 销售发货单号，多个用，分割
          deliveryNumbers: '',
          // 销售发货Id，多个用，分割
          deliveryIdList: '',
          /**
           * 认款总计
           */
          totalReceivedAmount: 0,

          /**
           * 冲预收总计
           */
          totalWriteOffAmount: 0,
          invoicedDate: '',
          actualInvoiceNo: '',
          expressNo: '',
          vatRate: '',
          vatAmount: '',
          /**
           * 邮寄联系人
           */
          contactName: '',

          /**
           * 联系电话
           */
          contactMobile: '',
          /**
           * 联系人邮编
           */
          postCode: '',
          /**
           * 邮寄地址
           */
          postAddress: '',

          // 发货明细
          deliveryCommodityList: [],
          // 冲余款明细
          offsetAdvanceList: [],
          // 开票明细
          commodityDetailList: []
        },
        woCode: 'importSalesInvoice',
        processInstanceName: '销售开票',
      };
    },
    props: {},
    methods: {
      /**
       * 根据文件id和文件名生成预览链接
       * @param id 附件id
       * @param fileName 附件名
       * @returns {string}
       */
      handleFileView (id, fileName) {
        this.$FilePrvwUtils.openFile(id,fileName);
      },
      downloadAttachment(id, fileName) {
        this.$myHttp({
          method: "post",
          url: "/microarch/sys/sysAttchement/download",
          data: {
            "entity": {
              "id": id
            }
          },
          responseType: "arraybuffer"
        }).then(res => {
          // 成功回调
          var headers = res.headers;
          var blob = new Blob([res.data], {
            type: headers["content-type"]
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        });
      },
      getAttachmentList() {
        this.$myHttp({
          method: "post",
          url: "/microarch/sys/sysAttchement/list",
          data: {
            "entity": {
              "bizId": this.ruleForm.id
            }
          }
        }).then(res => {
          let backData = res.data;
          if (backData) {
            this.attachmentList = backData;
          }
        });
      },
      getProcessMappingId() {
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.$myHttp({
          method: 'post',
          url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
          data: {
            entity: {
              woCode: this.woCode,
              orgId: userInfo.companyId,
              deptId: userInfo.deptId
            }
          }
        }).then(res => {
          if (res.data.ext.code === '2000') {
            this.mappingId = res.data.ext.mappingId;
          } else {
            Toast.fail({
              message: res.data.ext.message,
              type: 'error',
              duration: 1500
            });
          }
        });
      },
      backfilleditData(id) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/eas/salesInvoice/view',
          data: {
            entity: {
              id: id
            }
          }
        }).then(res => {
          // 成功回调方法
          var data = res.data.entity;
          this.ruleForm = data;
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.invoiceNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          // this.findData();
          this.getProcessMappingId();
          this.getAttachmentList();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        });
      },
      findData () {
        let codeSet = new Set();
        this.ruleForm.deliveryCommodityList.forEach(it => {
          if (it.salesContractNo) {
            codeSet.add(it.salesContractNo);
          }
        });

        this.ruleForm.offsetAdvanceList = [];
        this.loading = true;
        this.$myHttp({
          url: '/microarch/sale/saleSubscriptionHeader/getReceiveDetailList',
          method: 'POST',
          data: Array.from(codeSet)
        }).then(res => {
          this.ruleForm.offsetAdvanceList = res.data.rows;
          this.$nextTick(() => {
            this.loading = false;
          });
        }).catch((_) => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
      },
      setApprovalData(row) {
        if (row && row.pid) {
          this.ruleForm.id = row.businessKey;
          this.taskInstanceId = row.id;
          this.taskType = row.taskType;
        }
      },
      /**
       * 业务提交之前校验
       * */
      checkBeforeProcess(type) {
        if (type) {
          if (type === 'beforeAddsign') {
            this.$refs.demoTemperatureEdit.beforeAddsign();
          } else if (type === 'afterAddsign') {
            this.$refs.demoTemperatureEdit.afterAddsign();
          } else if (type === 'endProcess') {
            this.$refs.demoTemperatureEdit.endProcess();
          } else if (type === 'repelProcess') {
            this.repelProcess();
          } else {
            if (!this.taskInstanceId) {
              var params = {
                businessKey: this.ruleForm.id,
                mappingId: this.mappingId,
                processInstanceName: this.processInstanceName,
                userName: sessionStorage.getItem('userName'),
                variables: this.variables
              };
              this.startProcessAction(params);
            } else {
              this.$refs.demoTemperatureEdit.submit();
            }
          }
        } else {
          Toast.fail({
            message: this.$t('eas.数据校验失败'),
            type: 'warning'
          });
        }
      },
      // 业务发起流程
      startProcessAction(params) {
        if (params.mappingId) {
          params.mappingId = this.mappingId;
        }
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.invoiceNo, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/eas/salesInvoice/start',
          data: params
        })
          .then(res => {
            var backData = res.data.ext;
            if (backData) {
              this.$refs.demoTemperatureEdit.submitLoading = false;
              this.$refs.demoTemperatureEdit.isStart = true;
              this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
              this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
              this.$refs.demoTemperatureEdit.isFirstNode = true;
              this.$refs.demoTemperatureEdit.taskId = backData.taskId;
              this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
              this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
            } else {
              Toast.fail({
                message: this.$t('eas.流程发起异常'),
                type: 'warning'
              });
              this.$refs.demoTemperatureEdit.submitLoading = false;
            }
          })
          .catch(_ => {
            this.$refs.demoTemperatureEdit.submitLoading = false;
          });
      },
      // 业务提交流程
      submitProcessAction(params) {
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.invoiceNo, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/eas/salesInvoice/submit',
          data: params
        })
          .then(res => {
            if (res.data.ext.success) {
              this.processCallback(null, null, this.taskType);
            } else {
              Toast.fail({
                message: res.data.ext.msg,
                type: 'warning'
              });
            }
          })
          .catch(_ => {});
      },
      /***
       * 业务撤销流程
       */
      repelProcess() {
        var _this = this;
        _this
          .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
            cancelButtonClass: 'sysBackBtn'
          })
          .then(_ => {
            this.$myHttp({
              method: 'post',
              url: '/microarch/eas/salesInvoice/undoProcess',
              data: {
                entity: {
                  id: this.ruleForm.id,
                  processInstanceId: this.ruleForm.processInstanceId
                }
              }
            })
              .then(res => {
                if (res.data.success === undefined || res.data.success) {
                  Toast.fail({
                    message: this.$t('eas.撤销成功'),
                    type: 'success'
                  });
                  this.processCallback(null, null, this.taskType);
                } else {
                  Toast.fail({
                    message: res.data.msg,
                    type: 'warning'
                  });
                }
              })
              .catch(_ => {});
          })
          .catch(_ => {});
      },

      /***
       * 关闭发起流程审批窗口
       * 删除流程实例，回滚业务状态
       */
      closeStartCallback(pid) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/eas/salesInvoice/deleteProcess',
          data: {
            entity: {
              id: this.ruleForm.id,
              processInstanceId: pid
            }
          }
        })
          .then(res => {
            this.$refs.demoTemperatureEdit.dialogVisible = false;
            this.$refs.demoTemperatureEdit.taskId = '';
            this.$refs.demoTemperatureEdit.processInstanceId = '';
            this.processCallback(null, null, this.taskType);
          })
          .catch(_ => {});
      },

      /**
       * 提交之前校验
       * */
      beforeSubmitCallBack(params) {
        // 业务参数校验 此时可以从params中获取提交审批所有的参数
        // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
        // 不通过给出相应的提示或者触发其他业务操作
        // let selectUserArray = params.candidateUserIds[params.nextId]
        // console.log(selectUserArray);
        this.submitProcessAction(params);
      },
      /**
       * 流程审批之后业务数据更改
       * */
      processCallback(piid, optionType, taskType) {
        var _this = this;
        // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
        // // 成功回调方法
        // if (taskType === 'transated') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/haveList'
        //   });
        // } else if (taskType === 'waitfor') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/toDoList'
        //   });
        // } else {
        //   this.$router.push({
        //     name: 'sale/return/saleReturnApplyList'
        //   });
        //   this.$bus.emit('saleReturnApply-SaveSuccess');
        // }
        this.$router.go(-1);
      }
    },
    created() {},
    mounted() {
      let selectRow = JSON.parse(this.$route.query.row);
      if (selectRow) {
        this.ruleForm.id = selectRow.id;
        if (selectRow.businessKey) {
          this.ruleForm.id = selectRow.businessKey;
        }
      }
      if (this.ruleForm.id) {
        this.backfilleditData(this.ruleForm.id);
      }

      if (selectRow) {
        this.setApprovalData(selectRow);
      }
    },
    filters: {
      setDict(v, dictName) {
        return util.setDict(v, dictName);
      },
      formatAmount(v, decimal = 2, isCovering = true) {
        return util.formatAmount(v, decimal, isCovering);
      }
    }
  };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      font-weight: bold;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
